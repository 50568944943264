<template>
  <section>
    <b-container>
      <b-row v-if="payload.title" class="mb-5">
        <b-col class="text-center">
          <h2>{{ payload.title }}</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="mb-5" v-for="(img,index) in payload.gallery" :key="index">
          <VuePureLightbox
            :thumbnail="img.sizes.gallery_thumbnail"
            style="z-index: 100"
            :open-at-index="index"
            :images="allGalleryImages"
          />
        </b-col>
      </b-row>
      <b-row v-if="payload.link" class="mt-5">
        <b-col class="text-center">
          <a class="btn btn-light" :href="payload.link.url">{{ payload.link.title }}</a>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import VuePureLightbox from 'vue-pure-lightbox'

export default {
  name: "Gallery",
  props: ['payload'],
  components: { VuePureLightbox },
  computed: {
    allGalleryImages() {
      let images = []
      this.payload.gallery.forEach(img => {
        images.push(img.url)
      })
      return images
    }
  }
}
</script>

<style scoped lang="scss">
section {
  padding: 4rem 0;
}
img {
  max-width: 100%;
}
</style>