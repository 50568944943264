<template>
    <div>
        <b-form @submit="handleFormSubmit">
            <b-form-group>
                <b-form-input v-model="name" required placeholder="Imię i nazwisko"></b-form-input>
            </b-form-group>

            <b-form-group>
                <b-form-input v-model="email" type="email" required placeholder="Email"></b-form-input>
            </b-form-group>

            <b-form-group>
                <b-form-textarea v-model="content" required placeholder="Treść wiadomości" rows="8"></b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary">Wyślij</b-button>
        </b-form>
    </div>
</template>

<script>
    import ApiClient from './../../store/wp_api_client'
    const api = new ApiClient(process.env.VUE_APP_API_URL)

    export default {
        name: "ContactForm",
        data() {
            return {
                form_submitted: false,
                name: null,
                email: null,
                content: null,
            }
        },
        methods: {
            handleFormSubmit(e) {
                e.preventDefault()
                api.sendContactForm({
                    name: this.name,
                    email: this.email,
                    content: this.content
                })
                    .then((response) => {
                        this.form_submitted = true;
                        this.resetForm()
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            resetForm() {
                this.name = null
                this.email = null
                this.content = null
            }
        }
    }
</script>

<style scoped>

</style>