<template>
  <b-carousel v-if="payload.slides" :indicators="true" img-height="550px">
    <b-carousel-slide v-for="(slide, index) in payload.slides" :key="index">
        <template #img>
          <img class="d-block h-100 img-slide" :src="slide.image.sizes.slide">
        </template>
      <div class="text-left">
        <h2>{{ slide.title }}</h2>
        <a class="btn btn-light" :href="slide.link.url">{{ slide.link.title }}</a>
        <div class="spacer"></div>
      </div>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  name: "Slider",
  props: ['payload']
}
</script>

<style lang="scss">
.carousel-item {
  .img-slide{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
<style scoped lang="scss">

  @media (min-width: 1400px){
    h2 { font-size: 6rem; }
    .spacer { height: 8rem; }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    h2 { font-size: 5rem; }
    .spacer { height: 4rem; }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    h2 { font-size: 4.5rem; }
    .spacer { height: 2rem; }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    h2 { font-size: 3rem; }
    .spacer { height: 1rem; }
  }
  @media (max-width: 767px) {
    h2 { font-size: 3rem; }
    .spacer { height: 1rem; }
    .carousel-item {
      height: 550px;
    }
  }

  h2 {
    font-weight: 700;
    opacity: 0.75;
    text-shadow: 0 4px 4px rgba(0,0,0,0.5);
  }
  a.btn {
    margin-top: 3rem;
  }
</style>
<style lang="scss">
.carousel-indicators {
  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-left: 4px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    opacity: 1;
    &.active {
      background-color: $primary;
    }
  }
}
</style>