<template>
    <section :style="'background-image: url('+payload.image.url+')'">
        <b-container>
            <b-row align-v="center" align-h="between" class="flex-column-reverse flex-lg-row">
                <b-col class="text-center">
                    <h3 class="mb-5" v-if="payload.title" v-html="payload.title"></h3>
                    <p class="m-0" v-if="payload.content" v-html="payload.content"></p>
                    <a class="btn btn-primary mt-5" v-if="payload.link" :href="payload.link.url">{{ payload.link.title }}</a>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "FeaturedContentImage",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
    section {
        padding: 4rem 0;
        background: $primary;
        color: $white;
        overflow: hidden;
    }

    h3 {
        font-size: 1.75rem;
    }

    h3, p {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>