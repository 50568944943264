<template>
    <div>
        <component v-for="(b,i) in blocks" :is="'block_' + b.acf_fc_layout" :key="i" :payload="b"></component>
    </div>
</template>

<script>
    import Header from './blocks/Header'
    import ContentSections from "./blocks/ContentSections";
    import FeaturedContentImage from "./blocks/FeaturedContentImage";
    import Contact from "./blocks/Contact";
    import Download from "./blocks/Download";
    import Slider from "./blocks/Slider";
    import Gallery from "./blocks/Gallery";
    import SimpleText from "@/components/blocks/SimpleText.vue";

    export default {
        name: "Blocks",
        components: {
            'block_heading': Header,
            'block_slider': Slider,
            'block_bg_img_content_section': FeaturedContentImage,
            'block_content_sections': ContentSections,
            'block_gallery': Gallery,
            'block_contact': Contact,
            'block_section_text': SimpleText,
            'block_downloads': Download,
        },
        props: {
            blocks: {
                type: Array,
                required: true,
            },
        },
    }
</script>

<style scoped>

</style>
