<template>
    <section :class="payload.variant === 'decorated' ? 'decorated-top-white' : ''">
        <b-container>
            <b-row v-if="payload.title">
                <b-col class="text-center">
                    <h2 v-html="payload.title"></h2>
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col cols="12" lg="4">
                    <b-row class="mb-5 pb-5" v-if="!payload.cards">
                        <b-col class="content_container" v-html="payload.content"></b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" lg="5" offset-lg="1">
                    <contact-form></contact-form>
                </b-col>
                <b-col v-if="payload.cards" class="content_container" v-html="payload.content" cols="12" lg="10"></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import ContactForm from "../utility/ContactForm";

    export default {
        name: "Contact",

        components: {ContactForm},

        props: {
            payload: Object
        },
    }
</script>

<style scoped lang="scss">
    .contrast {
      .content_container {
        color: $white;
      }
    }

    section {
        padding-top: 120px;
        padding-bottom: 144px;
    }

    .content_container {
        color: $black;

        p {
          font-size: 0.9375rem;
          font-weight: 400;
          line-height: 27px;
        }
    }

    h2 {
        margin-bottom: 104px;
    }

    .contact_card_container {
        margin-bottom: 64px;
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>