<template>
    <div class="sections_container">
        <section v-for="(sec, i) in payload.sections" :key="i">
            <b-container>
                <b-row align-v="center" align-h="between" :class="i % 2 != 1 ? 'flex-row-reverse' : ''">
                    <b-col cols="12" lg="5">
                        <img :src="sec.image.url" :class="i % 2 == 1 ? 'odd' : 'even'" />
                    </b-col>

                    <b-col class="text-content" cols="12" lg="6">
                        <h2 v-if="sec.title" class="mb-3">{{ sec.title }}</h2>
                        <hr/>
                        <p v-if="sec.description">{{ sec.description }}</p>
                        <b-link v-if="sec.link" :to="sec.link.url" class="cta_btn">
                            <b-button variant="primary">{{ sec.link.title }}
                              <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2684 5.48817C15.3854 5.3981 15.3854 5.22165 15.2684 5.13158L9.11225 0.392582C8.9643 0.278688 8.75 0.384159 8.75 0.570873V4.18488H0.875C0.667893 4.18488 0.5 4.35277 0.5 4.55988V6.05988C0.5 6.26699 0.667893 6.43488 0.875 6.43488H8.75V10.0489C8.75 10.2356 8.9643 10.3411 9.11225 10.2272L15.2684 5.48817Z" fill="white"/>
                              </svg>
                            </b-button>
                        </b-link>
                        <a v-if="sec.file" :href="sec.file.url" target="_blank" class="cta_btn">
                          <b-button variant="light">Pobierz mapę trasy
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.175 1.875C8.175 1.66789 8.34289 1.5 8.55 1.5H9.45C9.65711 1.5 9.825 1.66789 9.825 1.875V8.0625H12.796C12.9768 8.0625 13.0837 8.26496 12.9818 8.41431L9.18586 13.9776C9.09653 14.1085 8.90347 14.1085 8.81414 13.9776L5.01818 8.41432C4.91627 8.26496 5.02323 8.0625 5.20404 8.0625H8.175V1.875Z" fill="black"/>
                              <path d="M1.125 15C0.917893 15 0.75 15.1679 0.75 15.375V16.125C0.75 16.3321 0.917893 16.5 1.125 16.5H16.875C17.0821 16.5 17.25 16.3321 17.25 16.125V15.375C17.25 15.1679 17.0821 15 16.875 15H1.125Z" fill="black"/>
                            </svg>
                          </b-button>
                        </a>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: "ContentSections",
        props: ['payload'],
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .contrast {
      section {
        background: $black;
        color: $white;
        &:nth-child(even) {
          background: $black;
        }
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
    }

    hr {
      border-width: 4px;
      border-color: $primary;
    }

    p {
      margin-top: 2.25rem;
    }

    .cta_btn {
      margin-top: 2.25rem;
    }

    a { display: block; }

    .sections_container {
        background: $white;
        overflow: hidden;

        img{
            width: 100%;
        }
    }

    section:nth-child(even) {
      background: #F0F4FB;
    }

    section {
        padding: 5rem 0;

        .text-content{
            p:last-of-type{ margin-bottom: 0; }
        }        
    }
</style>