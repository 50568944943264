<template>
    <section>
        <b-container>
            <b-row>
                <b-col>
                    <file-listing :items="all_files"></file-listing>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    import FileListing from "../utility/FileListing";
    export default {
        name: "Download",
        components: {FileListing},
        props: ['payload'],
        data() {
            return {
                page: 0,
            }
        },
        computed: {
            all_files() {
                return this.payload.files
            },
        },
    }
</script>

<style scoped lang="scss">
    section {
        padding-top: 80px;
        padding-bottom: 80px;
    }
</style>